html, body {
	padding: 0;
	margin: 0;
	background: #343642;
}

body {
	min-height: 100vh;
	color: $dark-grey;
	-webkit-font-smoothing: antialiased;
}

h2, h2 a {
	color: $dark-grey;

	&:hover {
		text-decoration: none;
	}
}

h4 {
	margin: 0 0 20px 0;
}

img {
	max-width: 100%;
}

ul, ol {
	margin-top: 20px;
	margin-bottom: 20px;
	li {
		margin: 0 0 10px 0;
	}
}

code, pre, tt {
	font-family: Inconsolata,Consolas,Courier,"Courier New","Liberation Mono",monospace;
	font-size: .9em;
	white-space: pre-wrap;
	border-radius: 2px;
	line-height: 1.4;
	font-weight: 400;
	background-color: #404145;
	color: #FAFAFA;
	border-radius: 2px;
	padding: 2px 5px;
}