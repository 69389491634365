/*
  Darkly Pygments Theme
  (c) 2014 Sourcey
  http://sourcey.com
*/

.highlight {
  white-space: pre;
  overflow: auto;
  word-wrap: normal; /* horizontal scrolling */
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 10px 15px;
  background: #404145;
  color: #C1C2C3;
}
.highlight .hll { background-color: #ffc; }
.highlight .gd { color: #2e3436; background-color: #0e1416; }
.highlight .gr { color: #eeeeec; background-color: #c00; }
.highlight .gi { color: #babdb6; background-color: #1f2b2d; }
.highlight .go { color: #2c3032; background-color: #2c3032; }
.highlight .kt { color: #e3e7df; }
.highlight .ni { color: #888a85; }
.highlight .c,.highlight .cm,.highlight .c1,.highlight .cs { color: #8D9684; }
.highlight .err,.highlight .g,.highlight .l,.highlight .n,.highlight .x,.highlight .p,.highlight .ge,
.highlight .gp,.highlight .gs,.highlight .gt,.highlight .ld,.highlight .s,.highlight .nc,.highlight .nd,
.highlight .ne,.highlight .nl,.highlight .nn,.highlight .nx,.highlight .py,.highlight .ow,.highlight .w,.highlight .sb,
.highlight .sc,.highlight .sd,.highlight .s2,.highlight .se,.highlight .sh,.highlight .si,.highlight .sx,.highlight .sr,
.highlight .s1,.highlight .ss,.highlight .bp { color: #C1C2C3; }
.highlight .k,.highlight .kc,.highlight .kd,.highlight .kn,.highlight .kp,.highlight .kr,
.highlight .nt { color: #729fcf; }
.highlight .cp,.highlight .gh,.highlight .gu,.highlight .na,.highlight .nf { color: #E9A94B ; }
.highlight .m,.highlight .nb,.highlight .no,.highlight .mf,.highlight .mh,.highlight .mi,.highlight .mo,
.highlight .il { color: #8ae234; }
.highlight .o { color: #989DAA; }
.highlight .nv,.highlight .vc,.highlight .vg,.highlight .vi { color: #fff; }