input[type="text"], input[type="email"] {
	display: block;
	width: 100%;
	font-size: 1.3em;
	padding: 10px;
	box-sizing: border-box;
	border-radius: 3px;
	border: 1px solid #ccc;
}

input[type="submit"] {
	background: #2f8cff;
	color: #fff;
	border: 0;
	padding: 9px 10px;
	font-size: 1em;
	margin-top: 9px;
	border-radius: 3px;
	width: 100%;
	cursor: pointer;
}

input {
	outline: none;
}

input[type=search] {
	-webkit-appearance: textfield;
	-webkit-box-sizing: content-box;
	font-size: 100%;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
	display: none;
}


input[type=search] {
	border: solid 1px #ccc;
	padding: 7px 14px 7px 32px;

	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}


input:-moz-placeholder {
	color: #ccc;
}
input::-webkit-input-placeholder {
	color: #ccc;
}