.faq {
	ul {
		padding: 0;
		margin: 0 0 50px 0;
		list-style: none;
	}

	li {
		font-size: 1em;

		a {
			color: $medium-grey;
			text-decoration: none;
			display: block;
			border-bottom: 1px solid #eee;
			padding: 5px 0;

			&:hover {
				color: $dark-grey;
			}
		}
	}
}