#search-results {
	margin: 0;
	padding: 0;

	li {
		list-style: none;
		margin: 0;
		padding: 0;

		h4 {
			margin: 20px 0 10px 0;
		}

		p {
			line-height: 1.5;
			padding: 0;
			margin: 0;
		}
	}
}

#search-query {
	font-weight: bold;
}