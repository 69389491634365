.banner {
  margin-bottom: 3rem;
  padding: 2rem;
}

// _sass/tutorials.scss:129
.banner--getting-started {
  background-color: $wanikani-orange;
  border-radius: $border-radius-size;
  position: relative;
  &:after {
    background-color: #ffffff;
    content: " ";
    position: absolute;
    width: 100%;
    height: 20px;
    bottom: -20px;
    left: 0;
  }
  &:before {
    background-image: url("/images/wanikani-logo.png");
    background-size: contain;
    content: " ";
    position: absolute;
    width: 192px;
    height: 151.875px;
    bottom: -20px;
    left: calc(50% - (151.875px / 2) - 20px);
  }
  h2, a {
    color: #ffffff;
  }
  h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 120px;
  }

  @media #{$mid-point} {
    &:before {
      left: 10px;
    }
    h2 {
      margin-left: 192px;
      margin-bottom: 0;
    }
  }
}
